'use client'

import { Container, Newsletter } from '@news12/storybook-news'

import { useCallback } from 'react'

import { trackGTMEvent } from '../../config/analytics'
import Regions from '../../config/regions'
import useNewsletter from '../Newsletter/useNewsletter'

import { useTheme } from 'styled-components'

export type NewsletterSectionProps = {
  regionName: string
}

const NewsletterSection = ({ regionName }: NewsletterSectionProps) => {
  const theme = useTheme()
  const { errorMessage, handleEmailChange, handleSubscribeClick } = useNewsletter(Regions[regionName]?.contentfulId)

  const handleNewsletterSubmit = useCallback(() => {
    handleSubscribeClick()
    trackGTMEvent.gaHamburgerNewsletterClick()
  }, [handleSubscribeClick])

  return (
    <Container fullWidth backgroundColor={theme.colors.skyBlue} className='newsletter-section'>
      <Container padding={{ default: '0 0', sm: '0 3.75rem' }}>
        <Newsletter
          padding={{ default: '1.375rem 1rem 2.5rem', md: '4rem 0' }}
          description='Topics you care about, straight to your inbox'
          errorMessage={errorMessage}
          heading='Be the first to know'
          onChange={handleEmailChange}
          onSubmit={handleNewsletterSubmit}
        />
      </Container>
    </Container>
  )
}

export default NewsletterSection
